import config from '@/project-config';

import { saveJwtToken, saveSessionId } from 'bubble-reducers/src/reducers/auth';
import {
  loadUser,
  setUserObjectIdOnly,
  storeGuest,
  storeUser,
} from 'bubble-reducers/src/reducers/user';

import {
  createCookie,
  getCookie,
  getInLocalStorage,
  parseJwtToken,
} from 'bubble-utils/src/auth-utils';

import bubbleUtils from 'bubble-utils';

export const checkAuthentication = (store) => {
  const storedJwtToken = getCookie(config.cookies.jwtToken.name);
  const storedSessionId =
    getCookie(config.cookies.sessionId.name) ||
    createCookie(
      config.cookies.sessionId.name,
      bubbleUtils.string.generateRandomString(24, false, true),
      config.cookies.defaultOptions,
    );

  if (storedJwtToken) {
    const tokenContent = parseJwtToken(storedJwtToken);
    const userInLocalStorage = getInLocalStorage(config.localStorage.user.keyName);

    // dispatch the token to our saveJwtToken action
    // which will update our redux state with the token and return true
    // save the token first for future api calls
    store.dispatch(saveJwtToken({ jwtToken: storedJwtToken }));
    // reuse local stored user/guest if it matches the objectId in JWT token otherwise load the new user
    if (
      userInLocalStorage &&
      userInLocalStorage.user &&
      tokenContent &&
      tokenContent.objectId === userInLocalStorage.user.objectId &&
      tokenContent.objectId
    ) {
      store.dispatch(storeUser({ user: userInLocalStorage.user }));
    } else if (
      userInLocalStorage &&
      userInLocalStorage.guest &&
      tokenContent.objectId === userInLocalStorage.guest.objectId &&
      tokenContent.objectId
    ) {
      store.dispatch(storeGuest({ guest: userInLocalStorage.guest }));
    } else if (tokenContent?.objectId && !tokenContent?.isGuest) {
      //TODO: load guest maybe?
      store.dispatch(loadUser({ userObjectId: tokenContent.objectId }));
    }
  }

  store.dispatch(saveSessionId({ sessionId: storedSessionId }));
};

export const checkAuthenticationOnServer = (store, cookies) => {
  let storedJwtToken = cookies[config.cookies.jwtToken.name];
  const storedSessionId = cookies[config.cookies.sessionId.name];

  // testing token for server side rendering
  // storedJwtToken =
  //   'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiaVBqWXRKRXlRTyIsIm9iamVjdElkIjoiaVBqWXRKRXlRTyIsInJvbGVzIjpbInVzZXIiLCJhZG1pbiIsImNhdGFsb2dfZWRpdG9yIl0sImlhdCI6MTU2NzQxOTkxOSwiZXhwIjoxNTcwMDExOTE5fQ.MmAbYzt1ML702ijMqzhOzKVMbGonnPssrv4OOnesF1g';
  if (storedJwtToken) {
    const tokenContent = parseJwtToken(storedJwtToken);

    // dispatch the token to our saveJwtToken action
    // which will update our redux state with the token
    // save the token first for future api calls and load the user
    store.dispatch(saveJwtToken({ jwtToken: storedJwtToken }));
    if (tokenContent && tokenContent.objectId) {
      store.dispatch(setUserObjectIdOnly(tokenContent.objectId));
      store.dispatch(loadUser({ userObjectId: tokenContent.objectId }));
    }
  }
  store.dispatch(saveSessionId({ sessionId: storedSessionId }));
};
