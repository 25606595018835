import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  addUserIapApi,
  addUserPremiumApi,
  buyIapApi,
  loadIapsApi,
  loadUserApi,
  updateIapPaymentIntentApi,
} from '../services/api';

import { storeUser } from '../reducers/user';
import { getClientToken } from '../selectors';
import { loadUser } from './user';

export const LOAD_IAPS = 'inAppPurchases/loadIaps';
export const BUY_IAP = 'inAppPurchases/buyIap';
export const UPDATE_IAP_PAYMENT_INTENT = 'inAppPurchases/updateIapPaymentIntent';
const ADD_USER_IAP = 'inAppPurchases/addUserIap';
const ADD_USER_PREMIUM = 'inAppPurchases/addUserPremium';

const initialState = {
  inAppPurchasesFromStores: [], // retrieved from stores
  iaps: [],
  iapBought: false,
  intent: null,
  loading: {},
  errors: {},
};

export const loadIaps = createAsyncThunk(LOAD_IAPS, async (params, { getState, dispatch }) => {
  const clientToken = getClientToken(getState());
  const iaps = await loadIapsApi(clientToken);
  return { iaps };
});

export const buyIap = createAsyncThunk(BUY_IAP, async (params, { getState, dispatch }) => {
  const userObjectId = params?.userObjectId || null;
  const productId = params?.productId || null;

  const clientToken = getClientToken(getState());
  const responseIap = await buyIapApi(clientToken, userObjectId, productId);

  // load user after buying IAP
  const user = await loadUserApi(clientToken, userObjectId);
  dispatch(storeUser({ user }));

  // call action after user has loaded
  return { paymentIntent: responseIap };
});

export const updateIapPaymentIntent = createAsyncThunk(
  UPDATE_IAP_PAYMENT_INTENT,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const productId = params?.productId || null;
    const paymentIntentId = params?.paymentIntentId || null;

    const clientToken = getClientToken(getState());
    const responseIap = await updateIapPaymentIntentApi(
      clientToken,
      userObjectId,
      productId,
      paymentIntentId,
    );

    if (responseIap.success || responseIap.paymentIntentStatus === 'succeeded') {
      // load user after buying IAP
      const user = await loadUserApi(clientToken, userObjectId);
      dispatch(storeUser({ user }));
    }
    return { paymentIntent: responseIap };
  },
);

export const addUserIap = createAsyncThunk(ADD_USER_IAP, async (params, { getState, dispatch }) => {
  const receipt = params?.receipt || null;
  const platform = params?.platform || null;
  const userObjectId = params?.userObjectId || null;

  const clientToken = getClientToken(getState());
  const response = await addUserIapApi(clientToken, userObjectId, receipt, platform);
  if (response?.status === 1) {
    // hook already received
    dispatch(loadUser({ userObjectId }));
  } else {
    // hook not received yet, delay loadUser
    setTimeout(() => {
      dispatch(loadUser({ userObjectId }));
    }, 10000);
  }
});

export const addUserPremium = createAsyncThunk(
  ADD_USER_PREMIUM,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const months = params?.months || null;
    const source = params?.source || null;

    const clientToken = getClientToken(getState());
    await addUserPremiumApi(clientToken, userObjectId, months, source);
    dispatch(loadUser({ userObjectId }));
  },
);

const inAppPurchasesSlice = createSlice({
  name: 'inAppPurchases',
  initialState,
  reducers: {
    resetInAppPurchasesReducer: {
      reducer: (state, action) => initialState,
    },
    resetBuyStatus: {
      reducer: (state, action) => {
        state.iapBought = false;
      },
    },
    setInAppPurchasesFromStores: {
      reducer: (state, action) => {
        state.inAppPurchasesFromStores = action.payload.inAppPurchasesFromStores;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadIaps
      .addCase(loadIaps.pending, (state, action) => {
        state.loading[LOAD_IAPS] = true;
        state.errors[LOAD_IAPS] = null;
      })
      .addCase(loadIaps.fulfilled, (state, action) => {
        state.iaps = action.payload.iaps;

        state.loading[LOAD_IAPS] = false;
        state.errors[LOAD_IAPS] = null;
      })
      .addCase(loadIaps.rejected, (state, action) => {
        state.loading[LOAD_IAPS] = false;
        state.errors[LOAD_IAPS] = action.error;
      })
      // buyIap
      .addCase(buyIap.pending, (state, action) => {
        state.loading[BUY_IAP] = true;
        state.errors[BUY_IAP] = null;
      })
      .addCase(buyIap.fulfilled, (state, action) => {
        const paymentIntent = action.payload.paymentIntent;
        state.intent = paymentIntent.paymentIntentId ? paymentIntent : null;
        state.iapBought =
          paymentIntent.success || paymentIntent.paymentIntentStatus === 'succeeded';

        state.loading[BUY_IAP] = false;
        state.errors[BUY_IAP] = null;
      })
      .addCase(buyIap.rejected, (state, action) => {
        state.loading[BUY_IAP] = false;
        state.errors[BUY_IAP] = action.error;
      })
      // updateIapPaymentIntent
      .addCase(updateIapPaymentIntent.pending, (state, action) => {
        state.loading[UPDATE_IAP_PAYMENT_INTENT] = true;
        state.errors[UPDATE_IAP_PAYMENT_INTENT] = null;
      })
      .addCase(updateIapPaymentIntent.fulfilled, (state, action) => {
        const paymentIntent = action.payload.paymentIntent;
        state.iapBought =
          paymentIntent.success || paymentIntent.paymentIntentStatus === 'succeeded';
        state.intent = paymentIntent.paymentIntentId ? paymentIntent : null;

        state.loading[UPDATE_IAP_PAYMENT_INTENT] = false;
        state.errors[UPDATE_IAP_PAYMENT_INTENT] = null;
      })
      .addCase(updateIapPaymentIntent.rejected, (state, action) => {
        state.loading[UPDATE_IAP_PAYMENT_INTENT] = false;
        state.errors[UPDATE_IAP_PAYMENT_INTENT] = action.error;
      })
      // addUserIap
      .addCase(addUserIap.pending, (state, action) => {
        state.loading[ADD_USER_IAP] = true;
        state.errors[ADD_USER_IAP] = null;
      })
      .addCase(addUserIap.fulfilled, (state, action) => {
        state.loading[ADD_USER_IAP] = false;
        state.errors[ADD_USER_IAP] = null;
      })
      .addCase(addUserIap.rejected, (state, action) => {
        state.loading[ADD_USER_IAP] = false;
        state.errors[ADD_USER_IAP] = action.error;
      })
      // addUserPremium
      .addCase(addUserPremium.pending, (state, action) => {
        state.loading[ADD_USER_PREMIUM] = true;
        state.errors[ADD_USER_PREMIUM] = null;
      })
      .addCase(addUserPremium.fulfilled, (state, action) => {
        state.loading[ADD_USER_PREMIUM] = false;
        state.errors[ADD_USER_PREMIUM] = null;
      })
      .addCase(addUserPremium.rejected, (state, action) => {
        state.loading[ADD_USER_PREMIUM] = false;
        state.errors[ADD_USER_PREMIUM] = action.error;
      });
  },
});

export default inAppPurchasesSlice.reducer;

export const { resetInAppPurchasesReducer, resetBuyStatus, setInAppPurchasesFromStores } =
  inAppPurchasesSlice.actions;
