import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { loadAlbum, loadAlbumSerie } from 'bubble-reducers/src/reducers/albums';
import { loadPrint } from 'bubble-reducers/src/reducers/prints';

import AlbumRevisionMenuModal from '@/components/Modals/AlbumRevisionMenuModal';
import AlbumRevisionModal from '@/components/Modals/AlbumRevisionModal';
import DuplicateAlbumModal from '@/components/Modals/DuplicateAlbumModal';
import MisplacedAlbumRevisionMenuModal from '@/components/Modals/MisplacedAlbumRevisionMenuModal';
import MoveAlbumToSerieModal from '@/components/Modals/MoveAlbumToSerieModal';
import MovePrintToAlbumModal from '@/components/Modals/MovePrintToAlbumModal';
import PrintRevisionModal from '@/components/Modals/PrintRevisionModal';
import SerieRevisionMenuModal from '@/components/Modals/SerieRevisionMenuModal';
import SerieRevisionModal from '@/components/Modals/SerieRevisionModal';
import ThankYouModal from '@/components/Modals/ThankYouModal';

import './Modals.css';

const RevisionModals = (props) => {
  const dispatch = useDispatch();

  const { albumObjectId, serieObjectId, printObjectId, sourcePage } = props;

  const [showSerieRevisionMenuModal, setShowSerieRevisionMenuModal] = useState(
    sourcePage === 'serie',
  );
  const [showSerieRevisionModal, setShowSerieRevisionModal] = useState(false);
  const [showDuplicateAlbumModal, setShowDuplicateAlbumModal] = useState(false);
  const [showAlbumRevisionMenuModal, setShowAlbumRevisionMenuModal] = useState(
    sourcePage === 'album',
  );
  const [showAlbumRevisionModal, setShowAlbumRevisionModal] = useState(false);
  const [showPrintRevisionModal, setShowPrintRevisionModal] = useState(false);
  const [showMisplacedAlbumRevisionMenuModal, setShowMisplacedAlbumRevisionMenuModal] =
    useState(false);
  const [showMoveAlbumToSerieModal, setShowMoveAlbumToSerieModal] = useState(false);
  const [showMovePrintToAlbumModal, setShowMovePrintToAlbumModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  useEffect(() => {
    if (
      !showSerieRevisionMenuModal &&
      !showSerieRevisionModal &&
      !showDuplicateAlbumModal &&
      !showAlbumRevisionMenuModal &&
      !showAlbumRevisionModal &&
      !showPrintRevisionModal &&
      !showMisplacedAlbumRevisionMenuModal &&
      !showMoveAlbumToSerieModal &&
      !showMovePrintToAlbumModal &&
      !showThankYouModal
    ) {
      return props.callback();
    }
  }, [
    showSerieRevisionMenuModal,
    showSerieRevisionModal,
    showDuplicateAlbumModal,
    showAlbumRevisionMenuModal,
    showAlbumRevisionModal,
    showPrintRevisionModal,
    showMisplacedAlbumRevisionMenuModal,
    showMoveAlbumToSerieModal,
    showMovePrintToAlbumModal,
    showThankYouModal,
  ]);

  useEffect(() => {
    dispatch(loadAlbum({ albumObjectId }));
    dispatch(loadAlbumSerie({ albumObjectId }));
    if (printObjectId) {
      dispatch(loadPrint({ objectId: printObjectId }));
    }
  }, [albumObjectId, printObjectId]);

  return (
    <>
      {showSerieRevisionMenuModal && (
        <SerieRevisionMenuModal
          show={showSerieRevisionMenuModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={sourcePage === 'album'}
          callback={() => setShowSerieRevisionMenuModal(false)}
          openSerieRevisionModal={() => {
            setShowSerieRevisionModal(true);
          }}
          openDuplicateAlbumRevisionModal={() => {
            setShowDuplicateAlbumModal(true);
          }}
          handleGoBack={() => {
            setShowAlbumRevisionMenuModal(true);
          }}
        />
      )}
      {showSerieRevisionModal && (
        <SerieRevisionModal
          show={showSerieRevisionModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowSerieRevisionModal(false)}
          handleGoBack={() => {
            setShowSerieRevisionMenuModal(true);
          }}
          openThankYourModal={() => {
            setShowThankYouModal(true);
          }}
        />
      )}
      {showDuplicateAlbumModal && (
        <DuplicateAlbumModal
          show={showDuplicateAlbumModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowDuplicateAlbumModal(false)}
          handleGoBack={() => {
            setShowSerieRevisionMenuModal(true);
          }}
        />
      )}

      {showAlbumRevisionMenuModal && (
        <AlbumRevisionMenuModal
          show={showAlbumRevisionMenuModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          callback={() => setShowAlbumRevisionMenuModal(false)}
          openAlbumRevisionModal={() => {
            setShowAlbumRevisionModal(true);
          }}
          openPrintRevisionModal={() => {
            setShowPrintRevisionModal(true);
          }}
          openMisplacedAlbumRevisionMenuModal={() => {
            setShowMisplacedAlbumRevisionMenuModal(true);
          }}
          openSerieRevisionMenuModal={() => {
            setShowSerieRevisionMenuModal(true);
          }}
        />
      )}
      {showAlbumRevisionModal && (
        <AlbumRevisionModal
          show={showAlbumRevisionModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowAlbumRevisionModal(false)}
          handleGoBack={() => {
            setShowAlbumRevisionMenuModal(true);
          }}
          openThankYourModal={() => {
            setShowThankYouModal(true);
          }}
        />
      )}
      {showPrintRevisionModal && (
        <PrintRevisionModal
          show={showPrintRevisionModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowPrintRevisionModal(false)}
          handleGoBack={() => {
            setShowAlbumRevisionMenuModal(true);
          }}
          openThankYourModal={() => {
            setShowThankYouModal(true);
          }}
        />
      )}

      {showMisplacedAlbumRevisionMenuModal && (
        <MisplacedAlbumRevisionMenuModal
          show={showMisplacedAlbumRevisionMenuModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowMisplacedAlbumRevisionMenuModal(false)}
          handleGoBack={() => {
            setShowAlbumRevisionMenuModal(true);
          }}
          openMoveAlbumToSerieModal={() => {
            setShowMoveAlbumToSerieModal(true);
          }}
          openMovePrintToAlbumModal={() => {
            setShowMovePrintToAlbumModal(true);
          }}
        />
      )}
      {showMoveAlbumToSerieModal && (
        <MoveAlbumToSerieModal
          show={showMoveAlbumToSerieModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowMoveAlbumToSerieModal(false)}
          handleGoBack={() => {
            setShowMisplacedAlbumRevisionMenuModal(true);
          }}
          openThankYourModal={() => {
            setShowThankYouModal(true);
          }}
        />
      )}
      {showMovePrintToAlbumModal && (
        <MovePrintToAlbumModal
          show={showMovePrintToAlbumModal}
          serieObjectId={serieObjectId}
          albumObjectId={albumObjectId}
          printObjectId={printObjectId}
          allowGoBack={true}
          callback={() => setShowMovePrintToAlbumModal(false)}
          handleGoBack={() => {
            setShowMisplacedAlbumRevisionMenuModal(true);
          }}
          openThankYourModal={() => {
            setShowThankYouModal(true);
          }}
        />
      )}

      {showThankYouModal && (
        <ThankYouModal show={showThankYouModal} callback={() => setShowThankYouModal(false)} />
      )}
    </>
  );
};

export default RevisionModals;
